<template>
	<div class="header">{{ user.firstname }} {{ user.lastname }}</div>
	<div class="body">
		<div class="fields">
			<div>Username</div>
			<div><input type="text" v-model="user.username" /></div>
			<div>Email</div>
			<div><input type="text" v-model="user.email" /></div>
			<div>Firstname</div>
			<div><input type="text" v-model="user.firstname" /></div>
			<div>Lastname</div>
			<div><input type="text" v-model="user.lastname" /></div>
			<template v-if="user.clinician && (!user.manager || !user.closed)">
				<div>Provided by</div>
				<div><input type="text" v-model="user.designedBy" /></div>
			</template>
			<div>pipedriveId</div>
			<div><input type="text" v-model="user.crmId" placeholder="[null]" /></div>
			<div>chargebeeId</div>
			<div><input type="text" v-model="user.billingId" placeholder="[null]" /></div>
		</div>
	</div>
	<div class="footer">
		<button @click="close">Close</button>
		<Richbutton :theme="canSave ? 'prim' : ''" :working="updating" :disabled="!canSave" @click="save">
			Save
		</Richbutton>
	</div>
</template>

<script>
	import { cloneDeep, pick } from "lodash"
	import { ref, computed, onMounted } from "vue"
	import { alertsList, users } from "@/store"
	import { Richbutton } from "@/components"

	export default {
		name: "EditUser",
		components: { Richbutton },
		props: {
			id: { type: Number, required: true }
		},
		emits: ["close"],
		setup(props, cntxt) {
			const alerts = alertsList()
			const userStore = users()
			const user = ref({})
			const saved = computed(() => userStore.users.value[props.id])
			const canSave = computed(() => JSON.stringify(user.value) !== JSON.stringify(saved.value))
			const close = (result) => cntxt.emit("close", result)
			const save = async () => {
				const payload = {
					...pick(user.value, ["firstname", "lastname", "email", "username", "designedBy"]),
					crmId: +user.value.crmId || null,
					billingId: user.value.billingId || null
				}
				try {
					const result = await userStore.updateUser(user.value.id, payload)
					alerts.push("Saved", "pos")
					close(result)
				} catch (err) {
					user.value = cloneDeep(saved.value)
				}
			}

			onMounted(() => {
				user.value = cloneDeep(saved.value)
			})

			return {
				...userStore,
				user,
				save,
				canSave,
				close
			}
		}
	}
</script>

<style lang="scss"></style>
