<template>
	<div class="users">
		<div class="flx gap-2">
			<Richbutton v-if="!userId && !orgId" :working="creating" theme="pos" @click="createUser">New</Richbutton>
			<input type="search" v-model="search" placeholder="Filter Users..." class="search flx-1 ba" />
			<vSelect
				v-model="selectedOrg"
				@search="(v) => (orgSearch = v)"
				:options="orgList"
				label="title"
				placeholder="Filter by Organization..."
				class="flx-1 ba"
			/>
			<Pagination :totalPages="totalPages" :page="paged" @click="setPage" class="flx-0" />
		</div>
		<DataTable :items="userList" class="bh bt mt-5">
			<template v-slot:thead>
				<tr>
					<th />
					<th>id</th>
					<th class="stickLeft">Users (Total: {{ total }})</th>
					<th>Type</th>
					<th>State</th>
					<th>Systems</th>
					<th>Provided by</th>
					<th>Organization</th>
					<th>Last Login</th>
					<th colspan="3">
						<div class="txt aln-cntr nowrap">30 Day / 90 Day</div>
						<div class="flx">
							<div class="flx-1 txt aln-lft">Logins</div>
							<div class="flx-1 txt aln-cntr">Prints</div>
							<div class="flx-1 txt aln-rgt">Sends</div>
						</div>
					</th>
					<th>Created</th>
				</tr>
			</template>
			<template v-slot:tbody="{ item }">
				<tr
					@click="(e) => showContextMenu(item.id, e)"
					:class="{
						selected: selectedUser.id === item.id,
						disabled: !item.enabled || item.orgClosed || (orgs[item.orgId] && orgs[item.orgId].closed)
					}"
				>
					<td class="min" @click.stop>
						<Spinner v-if="updating === item.id" />
						<input
							v-else-if="!item.orgClosed && !(orgs[item.orgId] && orgs[item.orgId].closed)"
							type="checkbox"
							:checked="item.enabled"
							@click.stop="toggleEnabled(item)"
						/>
					</td>
					<td class="ts" @click.stop>{{ item.id }}</td>
					<td class="stickLeft">
						<div>
							<span class="name">{{ item.lastname }}, {{ item.firstname }}</span>
							<span>&nbsp;&mdash; {{ item.username }}</span>
						</div>
						<div class="email">{{ item.email }}</div>
					</td>
					<td>
						<div v-if="item.manager" class="manager">Manager</div>
						<div v-if="item.clinician" class="clinician">
							{{ item.clinicianType }}
							<span v-if="!item.orgId" class="sole">(Sole)</span>
						</div>
					</td>
					<td>
						<div v-if="!item.enabled" class="closed">Disabled</div>
						<div v-else-if="item.orgClosed || (orgs[item.orgId] && orgs[item.orgId].closed)" class="closed">
							Organization Closed
						</div>
						<template v-else-if="item.clinician">
							<div v-if="item.closed" class="closed">Closed</div>
							<div v-else class="open">Open</div>
							<div v-if="!item.closed && item.closesOn" class="closesOn flx aln-cntr">
								<div>Closes: {{ item.closesOn.split("T")[0] }}</div>
								<button class="x ml-3" @click.stop="updateAttr(item.id, 'closesOn', null)" />
							</div>
						</template>
					</td>
					<td @click.stop>
						<div class="flx alg-cntr gap-1">
							<a
								v-if="item.crmId"
								:href="`https://simpleset.pipedrive.com/person/${item.crmId}`"
								target="_blank"
								class="tag bgGrdAcc"
								@click.stop
								>PD</a
							>
							<a
								v-if="item.billingId"
								:href="`https://${cbDomain}.chargebee.com/d/customers/${item.billingId}`"
								target="_blank"
								class="tag bgGrdPos"
								@click.stop
								>CB</a
							>
						</div>
						<div v-if="item.emr" class="emr">{{ item.emr }}</div>
					</td>
					<td>
						{{ item.designedBy }}
						<div
							v-if="item.clinician && item.designedBy !== `${item.firstname} ${item.lastname}`"
							class="mismatch"
						>
							Mismatch
						</div>
					</td>
					<td @click.stop="setOrgId(item.orgId)" class="orgCol">
						<div v-if="item.orgId" class="orgTitle">
							<span v-if="item.orgTitle" class="mr-4">{{ item.orgTitle }}</span>
							<span
								@click.stop="router.push({ name: 'Organizations', params: { id: item.orgId } })"
								class="emoji"
								>🏢</span
							>
							<!--<span v-if="item.orgSelfServe" class="selfServe">(Self Serve)</span>-->
						</div>
						<div v-for="(team, i) in item.teams" :key="i" class="team">{{ team.title }}</div>
					</td>
					<td class="ts">{{ item.lastLogin ? item.lastLogin.split("T")[0] : "-" }}</td>
					<td class="ts">{{ item.cntLogins1 }} / {{ item.cntLogins3 }}</td>
					<td class="ts">{{ item.cntPrints1 }} / {{ item.cntPrints3 }}</td>
					<td class="ts">{{ item.cntSends1 }} / {{ item.cntSends3 }}</td>
					<td class="ts">{{ item.created.split("T")[0] }}</td>
				</tr>
			</template>
			<template v-slot:noResults> No results </template>
			<template v-slot:tfoot>
				<div class="flx" v-if="userId">
					<button class="ml-a mr-a mt-5 ph-6 bgGrdAcc" @click="backToList">Show all Users...</button>
				</div>
			</template>
		</DataTable>

		<Transition name="slideDown">
			<ContextMenu v-if="contextMenu" :pos="contextMenu" @close="contextMenu = false">
				<header>{{ selectedUser.lastname }}, {{ selectedUser.firstname }}</header>
				<button @click="showEdit(selectedUser.id)">Edit</button>

				<template v-if="selectedUser.manager && selectedUser.clinician">
					<button @click="loginAs(selectedUser.id)">Login As</button>
					<button @click="resetPassword(selectedUser)">Reset Password</button>
					<button @click="router.push({ name: 'Folders', query: { userId: selectedUser.id } })">
						View Folders
					</button>
					<button @click="router.push({ name: 'Clients', query: { userId: selectedUser.id } })">
						View Clients
					</button>
					<button @click="router.push({ name: 'Organizations', params: { id: selectedUser.orgId } })">
						View Organization
					</button>
					<button v-if="selectedUser.closed" @click="updateAttr(selectedUser.id, 'closed', false)">
						Reopen Clinician
					</button>
					<button v-else @click="updateAttr(selectedUser.id, 'closed', true)">Close Clinician</button>
					<button @click="updateAttr(selectedUser.id, 'manager', false)">Revoke Manager Access</button>
					<button @click="changeOrg(selectedUser)">Change Organization</button>
				</template>

				<template v-else-if="selectedUser.manager">
					<button @click="loginAs(selectedUser.id)">Login As</button>
					<button @click="resetPassword(selectedUser)">Reset Password</button>
					<button @click="router.push({ name: 'Organizations', params: { id: selectedUser.orgId } })">
						View Organization
					</button>
					<button @click="updateAttr(selectedUser.id, 'clinicianType', 'practitioner')">
						Grant Clinician Access
					</button>
					<button @click="updateAttr(selectedUser.id, 'manager', false)">Revoke Manager Access</button>
					<button @click="changeOrg(selectedUser)">Change Organization</button>
				</template>

				<template v-else-if="selectedUser.clinician && selectedUser.closed">
					<button
						v-if="selectedUser.clinicianType === 'student'"
						@click="updateAttr(selectedUser.id, 'closed', false).then(() => closeIn6(selectedUser))"
					>
						Activate for 6 Months
					</button>
					<button v-else @click="updateAttr(selectedUser.id, 'closed', false)">Reopen</button>
				</template>

				<template v-else-if="selectedUser.clinician && !selectedUser.closed">
					<template v-if="selectedUser.clinicianType === 'student'">
						<button @click="loginAs(selectedUser.id)">Login As</button>
						<button @click="resetPassword(selectedUser)">Reset Password</button>
						<button @click="updateAttr(selectedUser.id, 'closed', true)">Close</button>
						<button @click="closeIn6(selectedUser)">Set Close in 6 Months</button>
						<button @click="closeOn(selectedUser)">Set Close On</button>
						<button @click="router.push({ name: 'Folders', query: { userId: selectedUser.id } })">
							View Folders
						</button>
						<button @click="router.push({ name: 'Clients', query: { userId: selectedUser.id } })">
							View Clients
						</button>
						<button
							v-if="selectedUser.orgId"
							@click="router.push({ name: 'Organizations', params: { id: selectedUser.orgId } })"
						>
							View Organization
						</button>
						<button @click="changeOrg(selectedUser)">
							<template v-if="selectedUser.orgId">Change</template
							><template v-else>Add</template> Organization
						</button>
						<button v-if="selectedUser.orgId" @click="convertToSole(selectedUser)">
							Leave Organization
						</button>
						<button @click="convertToPractitioner(selectedUser)">Convert to Practitioner</button>
						<button v-if="!selectedUser.orgId" @click="delUser(selectedUser)">Delete</button>
					</template>
					<template v-else-if="selectedUser.clinicianType === 'practitioner'">
						<button @click="loginAs(selectedUser.id)">Login As</button>
						<button @click="resetPassword(selectedUser)">Reset Password</button>
						<button @click="updateAttr(selectedUser.id, 'closed', true)">Close</button>
						<button @click="closeOn(selectedUser)">Set Close On</button>
						<button @click="router.push({ name: 'Folders', query: { userId: selectedUser.id } })">
							View Folders
						</button>
						<button @click="router.push({ name: 'Clients', query: { userId: selectedUser.id } })">
							View Clients
						</button>
						<button
							v-if="selectedUser.orgId"
							@click="router.push({ name: 'Organizations', params: { id: selectedUser.orgId } })"
						>
							View Organization
						</button>
						<button @click="changeOrg(selectedUser)">
							<template v-if="selectedUser.orgId">Change</template
							><template v-else>Add</template> Organization
						</button>
						<button v-if="selectedUser.orgId" @click="convertToSole(selectedUser)">
							Leave Organization
						</button>
						<button v-if="selectedUser.orgId" @click="updateAttr(selectedUser.id, 'manager', true)">
							Grant Manager Access
						</button>
						<button @click="convertToStudent(selectedUser)">Convert to Student</button>
						<button v-if="!selectedUser.orgId" @click="delUser(selectedUser)">Delete</button>
					</template>
				</template>

				<button
					v-if="!selectedUser.manager && !selectedUser.clinician"
					@click="updateAttr(selectedUser.id, 'clinician', true)"
				>
					Grant Clinician Access
				</button>

				<button
					v-if="selectedUser.orgId && !selectedUser.manager && !selectedUser.clinician"
					@click="updateAttr(selectedUser.id, 'manager', true)"
				>
					Grant Manager Access
				</button>
			</ContextMenu>
		</Transition>
	</div>
</template>

<script>
	import { debounce } from "lodash"
	import { ref, computed, watch, onMounted } from "vue"
	import { useRouter, useRoute } from "vue-router"
	import vSelect from "vue-select"
	import { api, alertsList, modals, orgs, users } from "@/store"
	import { ContextMenu, DataTable, Pagination, Richbutton, Spinner } from "@/components"
	import ChooseOrg from "../ChooseOrg"
	import ChooseDate from "../ChooseDate"
	import EditUser from "./EditUser"

	export default {
		name: "UserList",
		components: { ContextMenu, DataTable, Pagination, Richbutton, Spinner, vSelect },
		props: {},
		setup(props) {
			const router = useRouter()
			const route = useRoute()
			const modalStore = modals()

			// userList
			const userStore = users()
			const total = ref(0)
			const page = computed(() => +route.query.page || 1)
			const paged = ref(1)
			const perPage = ref(50)
			const totalPages = computed(() => Math.ceil(total.value / perPage.value))
			const orgId = computed(() => route.query.orgId)
			const userIds = ref([])
			const userList = computed(() => userIds.value.map((id) => userStore.users.value[id]).filter((v) => v))
			const userId = computed(() => +route.params.id || null)
			const search = ref("")
			const searched = ref("")
			const selectedUser = ref({})
			const cbDomain = ref("")
			const setPage = (v) => router.push({ query: { page: v, orgId: orgId.value }, name: "Users" })
			const setOrgId = (v) => {
				search.value = ""
				router.push({ query: { page: 1, orgId: v }, name: "Users" })
			}
			const fetchUsers = async (p) => {
				if (userId.value) {
					findUserById(userId.value)
				} else {
					const filter = { page: p || page.value }
					if (search.value) filter.search = search.value
					if (orgId.value) filter.orgId = orgId.value
					const result = await userStore.fetchUsers(filter)
					if (result) {
						searched.value = result.search
						total.value = result.total
						userIds.value = result.ids
						paged.value = result.page
						if (page.value !== paged.value) setPage(paged.value)
					}
				}
			}
			const debouncedFetchUsers = debounce(fetchUsers, 500)
			const findUserById = async (userId) => {
				if (!userStore.users.value[userId]) await userStore.fetchUser(userId)
				userIds.value = [userId]
				selectedUser.value = userStore.users.value[userId]
			}
			const backToList = () => {
				fetchUsers(1)
				router.push({ name: "Users" })
			}
			watch(search, () => {
				if (userId.value) router.push({ name: "Users" })
				debouncedFetchUsers(1)
			})
			watch(orgId, () => fetchUsers(1))
			watch(page, () => {
				if (page.value !== paged.value) {
					fetchUsers()
				}
			})
			watch(userId, (v) => {
				if (v) findUserById(v)
				else fetchUsers()
			})

			// orgsFilter
			const orgStore = orgs()
			const orgSearch = ref("")
			const selectedOrg = computed({
				get() {
					if (orgId.value && !orgStore.orgs.value[orgId.value]) orgStore.fetchOrg(orgId.value)
					const o = orgStore.orgs.value[orgId.value]
					return typeof o === "object" ? o : undefined
				},
				set(v) {
					setOrgId(v ? v.id : undefined)
				}
			})
			const orgIds = ref([])
			const orgList = computed(() => orgIds.value.map((id) => orgStore.orgs.value[id]))
			const fetchOrgs = debounce(async (search) => {
				const result = await orgStore.fetchOrgs({ search: search || "" })
				if (result) orgIds.value = result.ids
			}, 500)
			watch(orgSearch, fetchOrgs)

			// contextMenu
			const contextMenu = ref(false)
			const showContextMenu = (userId, { clientX, clientY }) => {
				selectedUser.value = userStore.users.value[userId]
				contextMenu.value = { x: clientX, y: clientY }
			}

			// actions
			const alerts = alertsList()
			const updateAttr = (userId, attr, val) =>
				userStore.updateUser(userId, { [attr]: val }).then((result) => {
					alerts.push("Done", "pos")
					return result
				})
			const toggleEnabled = (user, enabled = !user.enabled) =>
				userStore
					.updateUser(user.id, { enabled })
					.then((result) =>
						result.enabled ? alerts.push("Enabled", "pos") : alerts.push("Disabled", "warn")
					)
			const showEdit = (userId) => {
				modalStore.open.component({
					component: EditUser,
					props: { id: userId },
					size: "sm"
				})
			}
			const loginAs = async (userId) => {
				const result = await api.post(`admin/users/${userId}/login`)
				if (result) window.open(`https://${window.location.hostname.replace("admin.", "")}`)
			}
			const resetPassword = async (user) => {
				const index = await modalStore.open.dialog({
					header: "Reset password",
					body: `Confirm reset password on ${user.firstname} ${user.lastname}`,
					buttons: [
						{
							label: "Cancel"
						},
						{
							label: "Confirm"
						}
					]
				})
				if (index === 1) {
					const result = await updateAttr(user.id, "resetPassword", true)
					modalStore.open.dialog({
						header: `Reset password complete`,
						body: `New temp password: ${result.password}`
					})
				}
			}
			const convertToStudent = (user) => {
				const d = new Date()
				userStore
					.updateUser(user.id, {
						clinicianType: "student",
						closesOn: new Date(d.setMonth(d.getMonth() + 6))
					})
					.then((result) => alerts.push("Converted", "pos"))
			}
			const convertToPractitioner = (user) => {
				userStore
					.updateUser(user.id, {
						clinicianType: "practitioner",
						closesOn: null
					})
					.then((result) => alerts.push("Converted", "pos"))
			}
			const closeIn6 = (user) => {
				const d = new Date()
				userStore
					.updateUser(user.id, {
						closesOn: new Date(d.setMonth(d.getMonth() + 6))
					})
					.then((result) => alerts.push("Scheduled", "pos"))
			}
			const closeOn = async (user) => {
				const date = await modalStore.open.component({
					component: ChooseDate,
					size: "sm"
				})
				if (date) {
					userStore.updateUser(user.id, { closesOn: date }).then((result) => alerts.push("Scheduled", "pos"))
				}
			}
			const changeOrg = async (user) => {
				const org = await modalStore.open.component({
					component: ChooseOrg,
					size: "sm"
				})
				if (org && org.id !== user.orgId) {
					const confirm = await modalStore.open.dialog({
						header: "Confirm Change Organization",
						body: `
							You are about to assign the following User to the following Organization:<br /><br />
							User: <b>${user.firstname} ${user.lastname}</b><br />
							Organization: <b>${org.title}</b><br /><br />
							<b>Note:</b><br />
							${
								user.orgId
									? "User will be unjoined from old Organization<br />Clients owned by User will be unjoined from old Organization<br />	"
									: ""
							}
							User must be manually assigned to Teams @ new Organization<br />
							New Organization must have enough Seats<br />
						`,
						buttons: [
							{
								label: "Cancel"
							},
							{
								label: "Confirm"
							}
						]
					})
					if (confirm) await updateAttr(user.id, "orgId", org.id)
				}
			}
			const convertToSole = async (user) => {
				const confirm = await modalStore.open.dialog({
					header: "Convert to Sole",
					body: `
							You are about to remove the following User from the following Organiztion:<br /><br />
							User: <b>${user.firstname} ${user.lastname}</b><br />
							Organization: <b>${user.orgTitle}</b><br /><br />
							<b>Note:</b><br />
							Clients owned by User will be unjoined from old Organization<br />
						`,
					buttons: [
						{
							label: "Cancel"
						},
						{
							label: "Confirm"
						}
					]
				})
				if (confirm) await updateAttr(user.id, "orgId", null)
			}
			const createUser = async () => {
				const success = await userStore.createUser()
				if (success) {
					alerts.push("Created", "pos")
					fetchUsers(1)
				}
			}

			const delUser = async (user) => {
				const confirm = await modalStore.open.dialog({
					header: "Delete User",
					body: `
						You are about to delete the following User:<br /><br />
						User: <b>${user.firstname} ${user.lastname}</b><br /><br />
						<b>Note:</b><br />
						Users can only be deleted if all conditions are true:<br />
						Must be SOLE<br />
						No Clients<br />
						No content in Personal Folders (Exercises / Templates / Education / Outcomes)<br />		
					`,
					buttons: [
						{
							label: "Cancel"
						},
						{
							label: "Confirm"
						}
					]
				})
				if (confirm) {
					const userId = await userStore.deleteUser(user.id)
					if (userId) {
						userIds.value = userIds.value.filter((id) => id !== userId)
						alerts.push("Deleted", "pos")
					}
				}
			}

			onMounted(() => {
				fetchOrgs()
				fetchOrgs.flush()
				cbDomain.value = window.location.hostname.includes("dev") ? "simpleset-test" : "simpleset"
				if (route.params.id) findUserById(route.params.id)
				else fetchUsers()
			})

			return {
				router,
				...userStore,
				total,
				page,
				paged,
				perPage,
				totalPages,
				orgId,
				userList,
				search,
				searched,
				selectedUser,
				cbDomain,
				fetchUsers,
				setPage,
				setOrgId,
				userId,
				backToList,

				orgSearch,
				orgList,
				selectedOrg,
				orgs: orgStore.orgs,

				contextMenu,
				showContextMenu,

				toggleEnabled,
				updateAttr,
				showEdit,
				loginAs,
				resetPassword,
				convertToStudent,
				convertToPractitioner,
				closeIn6,
				closeOn,
				changeOrg,
				convertToSole,
				createUser,
				delUser
			}
		}
	}
</script>

<style lang="scss">
	.users {
		display: grid;
		grid-template-rows: auto 1fr;
		.dataTable {
			th.stickLeft:before {
				content: "";
				display: block;
				width: 200px;
			}
			tr.disabled {
				div,
				td,
				span {
					color: $clr-neg !important;
					background: $clr-neut-l2 !important;
				}
				.selfServe {
					display: none;
				}
			}
			.spinner {
				width: 18px;
				height: 18px;
			}
			.name,
			.orgTitle {
				color: $clr-prim;
				font-weight: bold;
			}
			.orgCol:not(:empty) {
				cursor: zoom-in;
			}
			.team {
				color: $clr-dark;
			}
			.manager {
				color: $clr-prim;
				font-weight: bold;
			}
			.clinician {
				text-transform: capitalize;
			}
			.emr {
				font-weight: bold;
				color: $clr-acc;
				font-size: $fnt-sml;
				text-transform: uppercase;
			}
			.sole {
				color: $clr-warn;
				font-weight: bold;
				font-size: $fnt-sml;
			}
			.selfServe {
				color: $clr-warn;
				font-weight: bold;
				font-size: $fnt-sml;
			}
			.closed {
				font-weight: bold;
				color: $clr-neg;
			}
			.mismatch {
				font-size: $fnt-sml;
				font-weight: bold;
				color: $clr-warn;
			}
			.open {
				color: $clr-pos;
			}
			.closesOn {
				> div {
					font-size: $fnt-sml;
				}
			}
			.emoji {
				cursor: pointer;
				font-size: 16px;
			}
		}
	}
</style>
